
import React from 'react'
import './contact.css';
import { FaRegEnvelope } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_7mjs23g', 'template_adad70c', form.current, {
        publicKey: 'ODe-7fFpQneaP4kkv',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  }; 
  return (
    <section id='contact'>
     <h5>Get In Touch</h5>
     <h2>Contact Me</h2>

     <div className="container contact_container">
      <div className="contact_options">
        <article className="contact_option">
        <FaRegEnvelope className="contact_option-icon" /> 
          <h4>Email</h4>
          <h5>info@ragnaagency.com</h5>
          <a href="mailto:info@ragnaagency.com" target="_blank">send a message</a>
        </article>

        <article className="contact_option">
        <FaXTwitter  className="contact_option-icon" />
        
          <h4>Twitter</h4>
          <h5>Tech News</h5>
          <a href="https://x.com/mawejjeandy" target="_blank">stay updated</a>
        </article>

        <article className="contact_option">
        <FaLinkedinIn  className="contact_option-icon"  />
          <h4>LinkedIn</h4>
          <h5>Mawejje Andrew</h5>
          <a href="https://www.linkedin.com/in/mawejje-andrew-a0aa93259/" target="_blank">send a message</a>
        </article>
      </div>
      {/* END OF CONTACT options */}
      <form ref={form} onSubmit={sendEmail}>
        <input type="text" name='name' placeholder='Your Full Name' required />
        <input type="email" name='email' placeholder='Your Email' required />
        <textarea name="message" id=""  rows="10" placeholder='Your Message' required></textarea>
        <button type='submit' className='btn btn-primary'>Send Message</button>
      </form>
     </div>
    </section>
  )
}

export default Contact