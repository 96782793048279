import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio5.png'
import IMG6 from '../../assets/portfolio6.jpg'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <article className="portfolio__item">
          <div className="portfolio__item-img">
            <img src={IMG1} alt="" />


          </div>
          <h3>Crypto Currency Dashboard & Financial Visualisation</h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/mawejjeandrew" className='btn'>Github</a>
            <a href="https://dribbble.com/shots/23917081-Cryptocurrency-Data-Analytic-Dashboard" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-img">
            <img src={IMG2} alt="" />


          </div>
          <h3>Charts templates & infographics in Figma</h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/mawejjeandrew" className='btn'>Github</a>
            <a href="https://dribbble.com/shots/14466982-Orion-UI-kit-Charts-templates-infographics-in-Figma" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-img">
            <img src={IMG3} alt="" />


          </div>
          <h3>Figma dashboard UI kit for data design web apps</h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/mawejjeandrew" className='btn'>Github</a>
            <a href="https://dribbble.com/shots/18542872-Eclipse-Figma-dashboard-UI-kit-for-data-design-web-apps" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-img">
            <img src={IMG4} alt="" />


          </div>
          <h3>Maintaining tasks and tracking progress</h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/mawejjeandrew" className='btn'>Github</a>
            <a href="https://dribble.com/Alien_pixels" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-img">
            <img src={IMG5} alt="" />


          </div>
          <h3>Charts templates & infographics</h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/mawejjeandrew" className='btn'>Github</a>
            <a href="https://dribbble.com/shots/16248633-Orion-UI-kit-Charts-templates-infographics-in-Figma" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-img">
            <img src={IMG6} alt="" />


          </div>
          <h3>Charts templates & infographics</h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com/mawejjeandrew" className='btn'>Github</a>
            <a href="" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
      </div>
    </section>
  )
}

export default Portfolio