import React from 'react'
import { FaLinkedin } from "react-icons/fa6";
import { FaSquareGithub } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";

const HeaderSocial = () => {
  return (
    <div className='header__socials'>
      <a href="https://www.linkedin.com/in/mawejje-andrew-a0aa93259/" target='_blank'><FaLinkedin /></a>
      <a href="https://github.com/mawejjeandrew" target='_blank'><FaSquareGithub /></a>
      <a href="https://www.instagram.com/ragna_agency/" target='_blank'><FaInstagramSquare /> </a>

    </div>
  )
}

export default HeaderSocial