import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/maw.png'
import HeaderSocial from './HeaderSocial'

const Header = () => {
  return (
   <header>
    <div className="container header__container">
      <h5>Hello I'm</h5>
      <h1>Mawejje Andrew</h1>
      <h5 className="text-light">Fullstack Developer</h5>
      <CTA />
      <HeaderSocial />

      <div className="me">
        <img src={ME} alt="" />
      </div>

      <a href='#contact' className='scroll__down'>Mawejje Andrew</a>
    </div>
   </header>
  )
}

export default Header