import React from 'react'
import './footer.css'
import { FaSquareGithub } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";


const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer_logo">Ragna</a>
      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonials">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer_socials">
        <a href="https://x.com/mawejjeandy" target='_blank'><FaXTwitter /></a>
        <a href="https://www.instagram.com/ragna_agency/" target='_blank'><FaInstagramSquare /></a>
        <a href="https://github.com/mawejjeandrew" target='_blank'><FaSquareGithub /></a>
      </div>
      <div className='footer_copyright'>
        <small>&copy; Mawejje Andrew</small>
      </div>
    </footer>
  )
}

export default Footer