import React from 'react'
import './about.css'; 
import ME from '../../assets/mosh.jpg'
import { TbAward } from "react-icons/tb";
import { LuUsers2 } from "react-icons/lu";
import { IoFolderOutline } from "react-icons/io5";

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-img">
            <img src={ME} alt="About Image" />
          </div>

        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <TbAward  className='about__icon'/>
              <h5>Experience</h5>
              <small>4+ Years Working</small>

            </article>

            <article className='about__card'>
              <LuUsers2  className='about__icon'/>
              <h5>Clients</h5>
              <small>300+ Clients WorldWide</small>

            </article>

            <article className='about__card'>
              <IoFolderOutline  className='about__icon'/>
              <h5>Projects</h5>
              <small>100+ Completed Project</small>

            </article>
          </div>
          <p>
          As a Fullstack Software Engineer with a strong problem-solving foundation, I specialize in developing automated systems and web applications that enhance business efficiency. With expertise in front-end and back-end development, I create seamless, scalable, and user-friendly solutions.

Proficient in various programming languages, frameworks, and tools, I deliver robust applications that drive digital transformation. Passionate about innovation and continuous learning, I stay updated with the latest tech trends to ensure future-proof solutions.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>


        </div>
      </div>
    </section>
  )
}

export default About