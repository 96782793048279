import React from 'react'
import './services.css'
import { FaCheck } from "react-icons/fa6";

const Services = () => {
  return (
    <section id='services'>
      <h5>What i Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className="service__list">
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Good visual design enhances the overall appeal and usability</p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Making the application intuitive and easy to navigate.</p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Adapts seamlessly to different devices and screen sizes.</p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Ensures the application is easy to use</p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Designs that cater to users with different abilities</p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p> Evokes positive emotions, creating a satisfying and enjoyable experience </p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Organizes and structures content in a logical way</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className="service__list">
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Creating tailored websites that meet specific business needs and goals</p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p> Ensuring that websites are fully optimized and functional across all devices</p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p> Building the client-side of the website</p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Developing the server-side of the website, including databases, ser</p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p> Implementing and customizing CMS platforms </p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Developing online stores with secure payment gateways, inventory management</p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Providing ongoing support to ensure websites remain secure, up-to-date</p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p> Ensuring websites are optimized for search engines to improve visibility and rankings</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>App Development</h3>
          </div>
          <ul className="service__list">
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Creating bespoke mobile applications tailored to specific business needs and user requirements.</p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Building applications that work seamlessly on multiple operating systems </p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Focusing on creating intuitive and engaging user experiences </p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Crafting visually appealing and consistent interfaces</p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Developing the server-side logic, database management, and API integration</p>
            </li>
            <li>
            <FaCheck  className='service__list-icon'/>
            <p>Conducting thorough testing to identify and fix bugs, ensure performance optimization</p>
            </li>
            
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services