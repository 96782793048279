import React from 'react'
import './nav.css'
import { IoHomeOutline } from "react-icons/io5";
import { CiUser } from "react-icons/ci";
import { FiBook } from "react-icons/fi";
import { RiServiceLine } from "react-icons/ri";
import { MdContactMail } from "react-icons/md";
import {useState} from 'react'
const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');
  return (
    <nav>
      <a href="#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><IoHomeOutline /></a>
      <a href="#about"onClick={() => setActiveNav('#about')} className={activeNav === 'about' ? 'active' : ''}><CiUser /></a>
      <a href="#experience"onClick={() => setActiveNav('#experience')} className={activeNav === 'experience' ? 'active' : ''}><FiBook /></a>
      <a href="#services"onClick={() => setActiveNav('#services')} className={activeNav === 'services' ? 'active' : ''}><RiServiceLine /></a>
      <a href="#contact"onClick={() => setActiveNav('#contact')} className={activeNav === 'contact' ? 'active' : ''}><MdContactMail /></a>
    </nav>
    
  )
}

export default Nav