import React from 'react'
import './testmonials.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

// import Swiper core and required modules
import {Pagination} from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



const Testmonials = () => {
  return (
    <section id='testmonials'>
      <h5>Review fom clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials_container"
           // install Swiper modules
           modules={[Pagination]}
           spaceBetween={40}
           slidesPerView={1}
           pagination={{ clickable: true }}>
        <SwiperSlide className="testimonial">
          <div className="client_avatar">
          <img src={AVTR1} alt="AVATOR ONE" />  
          </div>
          
            <h5 className='client_name'>Ernest Grace</h5>
            <small className='client_review'>
             We approached ragna agency to develop a custom app for our bakery, and we couldn't be happier with the results. The team took the time to understand our needs and created an app that is both user-friendly and visually appealing. Our customers love the convenience of ordering through the app, 
             and it has significantly boosted our sales. Ragna Agency's attention to detail and commitment
             to excellence are unmatched. Highly recommended!
            </small>
        </SwiperSlide>
        <SwiperSlide className="testimonial">
          <div className="client_avatar">
            <img src={AVTR2} alt="AVATOR ONE" />
            
          </div>
          <h5 className='client_name'>Stallon James</h5>
            <small className='client_review'>
              Ragna Agency transformed our outdated website into 
              a modern, sleek, and professional online presence.
             The new design not only looks fantastic but also 
             provides a seamless user experience for our clients. 
             The team was responsive, creative, and easy to work with throughout the entire process. Our website traffic has increased, and we've received numerous compliments on the new look.
             Ragna Agency truly exceeded our expectations
            </small>
        </SwiperSlide>
        <SwiperSlide className="testimonial">
          <div className="client_avatar">
            <img src={AVTR3} alt="AVATOR ONE" />
            
          </div>
          <h5 className='client_name'>Tyron james</h5>
            <small className='client_review'>
            Implementing an automation system developed by Ragna Agency has revolutionized our operations. The system has streamlined our processes, reduced errors, and increased efficiency, saving us both time and money. The team demonstrated a deep understanding of our industry and provided a solution tailored to our specific needs. Their expertise and dedication were evident at every stage of the project. We are incredibly
             grateful for their outstanding work and support.
            </small>
        </SwiperSlide>

        <SwiperSlide className="testimonial">
          <div className="client_avatar">
            <img src={AVTR4} alt="AVATOR ONE" />
            
          </div>
          <h5 className='client_name'>Amina Aisha</h5>
            <small className='client_review'>
            Working with Ragna Agency was a game-changer for our fitness
             studio. They provided a comprehensive solution that included a custom 
             app for booking classes, a redesigned website, and an automation system 
             for managing our membership database. The app has made it easier for our clients 
             to book and manage their schedules, while the new website attracts more visitors and 
             provides all the information our clients need. The automation system has streamlined our administrative tasks, 
             allowing us to focus more on our clients. The team's professionalism, creativity, and technical expertise are truly 
             impressive. We highly recommend Ragna Agency to any business looking to
             enhance their digital presence and operational efficiency!
            </small>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}

export default Testmonials